.buttonStyle {
  width: 128px !important;
  min-width: 128px !important;
  height: 50px !important;
}
.home_page_plan_card {
  min-width: 250px;
  width: 250px;
  margin: 0 10px 20px 13px;
  border-radius: 43px;
  box-shadow: 0 0 1px 1px var(--text_color_8), 0 0 0 0 var(--black);
  border: 1px solid var(--card-color);
  background-color: var(--white);
  position: relative;

  

  @media (max-width: 1366px) {
    min-width: 280px;
    width: 285px;
  }

  @media (max-width: 1024px) {
    margin: 0 10px 20px 10px;
    min-width: 325px;
  }

  @media (max-width: 600px) {
    margin: 0 0px 30px 0;
    min-width: 300px;
  }
}

.apply_border123 {
  border: 2px solid var(--primary_color);
}

.cards_div {
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
  margin-top: 50px;
}

.plans_div {
  width: 96% !important;
}

.fade_out {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.current_plan {
  border: 2px solid var(--primary_color);
}
.plan_page_plan_card {
  min-width: 270px !important;
  // padding: 20px 10px;
  width: 15% !important;
  border: 3px solid var(--plan_card_border_color);
}

.plan_page_plan_card:hover {
  border: 3px solid var(--primary_color);
  box-shadow: var(--plan_card_hover_box_shadow) !important;
}
.top_container {
  // padding: 21px 5px 25px 5px !important;
  padding-bottom: 20px;
  background-color: var(--plan_card_upper_bg_color); //var(--primary_color) !important;
  border-radius: 40px 40px 0px 0px;
}
.bottom_container {
  padding: 21px 23px 0 25px !important;
}

.tag {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 10px;
  border-radius: 40px 4px 4px 0;
  margin-left: 3px;
  font-size: 12px;
  font-weight: var(--font_weight_2);
}

.title {
  font-family: var(--font_family_Medium);
  font-size: 22px;
  font-weight: var(--font_weight_4) !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.59;
  letter-spacing: normal;
  text-align: center;
  color: var(--text_color);
}

.separator {
  width: 60px;
  height: 5px;
  border-radius: 2.5px;
  margin: 12px auto;
  background-color: var(--divider_color) !important;
}

.save_money_area {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-family: var(--font_family_Semibold);
  font-size: 12px;
  color: var(--text_color);

  .save_money_img {
    height: 15px;
    padding: 0 4px 4px 0;
  }
}

.plan_cost_data {
  text-align: center;

  .cost {
    margin: 0px;
    font-family: var(--font_family_Bold);
    font-size: 24px;
    font-weight: var(--font_weight_4);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--light_black_1);
  }

  .cost1 {
    margin: 11px 0 7px 0px;
    font-family: var(--font_family_Semibold);
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--light_black_2);
    font-weight: var(--font_weight_4);
  }

  &:hover {
    box-shadow: none;
  }
}

.plan_page_offer {
  margin-left: 10px;
  font-family: var(--font_family_Medium);
  font-size: 14px;
  font-weight: var(--font_weight_1);
  color: var(--text_color);
  font-stretch: normal;
  font-style: normal;
  line-height: 17px;
  letter-spacing: normal;
}