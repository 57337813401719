.checkout_left {
  padding: 2%;
  .left_sec_heading {
    display: flex;
    align-items: center;
    font-family: var(--font_family_Bold) !important;
    font-size: 20px;
    font-weight: var(--font_weight_4);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    margin-top: 20px;
    margin-bottom: 15px;
    color: var(--text_color);
    img {
      margin-right: 10px;
    }
    div {
      font-family: inherit !important;
    }
    .email_id_text{
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover{
        overflow: visible;
      }
    }
  }
  .line_checkout {
    border-top: solid 1px #bbbbbb;
    margin: 15px 0;
    @media (max-width: 1024px) {
      margin: 15px;
    }
  }
  .bill_content_title {
    font-family: var(--font_family_Semibold);
    font-size: 15px;
    font-weight: var(--font_weight_2);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--title-color_1);
    margin: 0 0 10px 0;
  }
  .bill_content_subtext {
    font-family: var(--font_family_Semibold);
    font-size: 14px;
    font-weight: var(--font_weight_2);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primary_brand_color_light);
  }
  .text_fields_main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .text_fields_div {
      width: 48%;
      margin-bottom: 20px;
    }
    @media (max-width: 600px) {
      flex-direction: column;
      .text_fields_div {
        width: 100%;
      }
    }
    .account_labels {
      font-family: var(--font_family_Semibold);
      font-size: 15px;
      font-weight: var(--font_weight_2);
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--warm_grey);
      margin: 0 0 10px 0;
    }
    .error_message {
      color: var(--danger);
      font-size: 12px;
      font-family: var(--font_family_Medium);
      line-height: 24px;
      text-align: left;
      margin: 5px 5px 0 5px;
    }
  }
  .note_div {
    font-family: var(--font_family_Semibold);
    color: var(--text_color);
    font-size: 14px;
    margin: 0px 0 20px;
    .note_head {
      font-family: var(--font_family_Bold);
      color: var(--primary_brand_color);
      font-size: inherit;
    }
  }
  .shipping_options {
    margin: 20px 0;
  }
  .radio_option_note {
    margin: 10px 0 20px;
  }
  .same_billing_address {
    display: flex;
    align-items: center;
    margin: 0 0 20px;
    .text {
      font-family: var(--font_family_Medium);
      font-size: 14px;
      font-weight: var(--font_weight_1);
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--text_color);
      margin-top: 2px;
    }
  }
  .billing_proceed {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .disable_section{
    pointer-events: none;
    opacity: 0.4;
  }
}
