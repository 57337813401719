.text-field-container {
    position: relative;
    display: inline-flex;
  }
  
  .text-field {
    padding-right: 30px; /* Add padding to the right to make space for the icon */
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .search-icon {
    position: absolute;
    right: 10px; /* Adjust as needed */
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* Prevents the icon from blocking input */
  }
  