@mixin text_styles($color, $weight) {
  font-family: var(--font_family_Medium);
  font-size: 14px;
  font-weight: $weight;
  color: $color;
}
.container {
  background-color: var(--primary_brand_color_light1);
  border-radius: 6px;
  padding: 24px 15px 24px 15px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px var(--white_2);
  margin: 20px 0;
  @include text_styles(var(--text_color), var(--font_weight_1));
  .input_container {
    display: flex;
    img {
      height: 55px;
      margin-left: -69px;
      z-index: 1;
      object-fit: contain;
      cursor: pointer;
      @media (max-width: 600px) {
        height: 55px;
        right: 13px;
      }
    }
  }
  .info {
    margin-left: 3px;
    position: relative;
    cursor: pointer;
  }
  .arrow_down {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid var(--white_1);
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.08);
    margin: 6px auto auto auto;
    position: absolute;
    left: 1px;
    top: -15px;
  }
  .simid_loader {
    margin-left: -64px;
    z-index: 1;
    height: 54.5px;
    width: 71px;
    object-fit: contain;
    background-color: var(--primary_brand_color);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white_3);
    border-radius: 0px 5px 5px 0px;
    @media (max-width: 600px) {
      top: 200px;
      right: 13px;
    }
  }
  .info_tooltip {
    width: 216px;
    height: auto;
    padding: 8px;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px var(--light-grey);
    background-color: var(--white_1);
    font-size: 12px;
    font-weight: var(--font_weight_1);
    font-family: var(--font_family_Medium);
    line-height: 1.67;
    text-align: center;
    color: var(--text_color);
    position: absolute;
    top: -65px;
    right: -185px;
    @media (max-width: 600px) {
      right: -10px;
    }
  }
  .info_tooltip_div {
    display: none;
  }
  .info:hover > .info_tooltip_div {
    display: block;
  }
  .success_msg {
    margin: 20px 0 0 3px;
    font-family: var(--font_family_Semibold);
    font-size: 14px;
    line-height: 1.57;
    color: var(--primary_color);
  }
  .failure_msg {
    margin-top: 5px;
    font-size: 12px;
    font-family: var(--font_family_Semibold);
    font-weight: 600;
    color: var(--red-color_2);
  }
  .simid_length {
    margin-top: 5px;
    font-size: 12px;
    font-family: var(--font_family_Semibold);
    color: var(--warm-grey);
  }
}
