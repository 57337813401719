.data_pack {
  font-size: 14px;
  font-weight: 600;
  color: var(--black);
  font-family: var(--font_family_Semibold);
  font-weight: var(--font_weight_2);
}
.arrow_icon {
  width: 18px;
  height: 18px;
  margin: 10px 5px 5px 10px;
}
.data_note {
  font-family: var(--font_family_Semibold);
  font-weight: var(--font_weight_2);
}
.plan_change_text {
  color: var(--primary_color) !important;
  font-family: var(--font_family_Semibold) !important;
  font-size: 14px !important;
  text-align: center !important;
}
.tabs_container {
  font-weight: 400;
  .buy_data {
    font-family: var(--font_family_Semibold);
    font-weight: var(--font_weight_2);
    font-size: 17px;
    text-align: center;
    padding: 10px 30px;
    border-radius: 55px;
    color: var(--primary_color);
    border: 1px solid var(--primary_color);
  }
  @media (min-width: 1024px) {
    width: 526px;
  }
  @media (max-width: 1024px) {
    width: 60%;
  }
  @media (max-width: 1023px) {
    width: 567px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
}
.plans_container {
  @media (min-width: 768px) {
    width: 526px;
  }
  @media (max-width: 768px) {
    width: 526px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
}
.hsd_message {
  color: var(--text_color);
  font-family: var(--font_family_Medium);
  font-size: 13px;
  margin: 26px 0 30px;
  text-align: center;
  span:nth-child(1) {
    color: var(--primary_background);
  }
  span:nth-child(2) {
    color: var(--primary_color);
  }
}
.account_card_expiry_message {
  font-family: var(--font_family_Semibold);
  font-size: 14px;
  margin: 5px 0 15px;
  text-align: center;
  span:nth-child(1) {
    color: var(--danger);
  }
}
.account_update_expiry_card {
  font-family: var(--font_family);
  font-size: 14px;
  cursor: pointer;
  color: var(--primary_color);
}


.home_page_plan_card {
  min-width: 235px;
  width: 235px;
  margin: 0 15px 20px;
  border-radius: 4px;
  background-color: var(--white);
  position: relative;
  .tag {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px 10px;
    border-radius: 4px 0 4px 0;
    font-size: 12px;
    font-weight: var(--font_weight_2);
  }

  .separator {
    width: 60px;
    height: 5px;
    border-radius: 2.5px;
    margin: 12px auto;
    background-color: var(--primary_color) !important;
  }
  .save_money_area {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-family: var(--font_family_Semibold);
    font-size: 12px;
    color: var(--text_color);
    .save_money_img {
      height: 15px;
      padding: 0 4px 4px 0;
    }
  }
  .view_detail {
    font-family: var(--font_family_Bold);
    font-size: 14px;
    font-weight: var(--font_weight_4);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin: 10px 0 0 0;
    color: var(--primary_color);
    cursor: pointer;
  }
  .plan_page_offer {
    margin-left: 10px;
    font-family: var(--font_family_Semibold);
    font-size: 14px;
    font-weight: var(--font_weight_2);
    color: var(--text_color);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
  }
  @media (max-width: 1366px) {
    min-width: 280px;
    width: 285px;
  }
  @media (max-width: 1024px) {
    margin: 0 10px 20px 10px;
    min-width: 325px;
  }
  @media (max-width: 600px) {
    margin: 0 0px 4px 0;
    min-width: 300px;
  }
}

.apply_border123 {
  border: 2px solid var(--primary_color);
}

.cards_div {
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
  margin-top: 45px;
  @media screen and (max-width: 600px) {
    margin-top: 19px;
  }
}
.plans_div {
  width: 100% !important;
}
.plan_page_plan_card {
  min-width: 235px !important;
  padding: 20px 10px;
  width: 15% !important;
  box-shadow: none !important;
}
.fade_out {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.current_plan {
  border: 2px solid var(--primary_color);
}

@media (max-width: 600px) {
  .wrapper_div {
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    display: flex;
    max-width: 100%;
    position: relative;
  }
  .wrapper_div::-webkit-scrollbar {
    display: none;
  }
  .cards_div {
    justify-content: flex-start;
    margin-left: 45px;
    gap: 25px;
    flex-wrap: nowrap !important;
  }
  .plans_div {
    .plan_page_plan_card {
      padding: 40px 20px;
    }
  }
  .cards_div::-webkit-scrollbar {
    display: none;
  }
}
